import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, Router} from "@angular/router";
import {ApiService} from "../Service/api.service";
import {AuthService} from "../Service/auth.service";
import {HTTPStatus} from "../Service/interceptor.service";
import {Title} from "@angular/platform-browser";
import {UserService} from "../Service/user.service";
import {User} from "../Model/User";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UserDialogPasswordComponent} from './user/user-dialog-password/user-dialog-password.component';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {FileContractService} from '../Service/file-contract.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {


  api_ok = false;
  on_check_api = true;
  HTTPActivity: boolean;
  user: User;
  changeVersion = false;
  snackBarRef;
  currentLang = 'fr';
  dialogRef: MatDialogRef<UserDialogPasswordComponent>;
  themeLogo = 'deepblock.png';

  constructor(private _router: Router,
              private _api: ApiService,
              private _titleService: Title,
              public _auth: AuthService,
              private httpStatus: HTTPStatus,
              private _user: UserService,
              private _snackBar: MatSnackBar,
              public dialog: MatDialog,
              public translate: TranslateService,
              private spinner: NgxSpinnerService,
  ) {
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      if (status) {
        setTimeout(() => {
          this.spinner.show();
        });
      } else {
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
      this.HTTPActivity = status;
    });

    translate.addLangs([
      'fr',
      'en'
    ]);

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('fr');

  }

  ngOnInit() {

    this._api.checkApiStatus().subscribe(
      (response) => {
        this.api_ok = response.ok;
        this.on_check_api = false;
        this.getUser();
      },
      (error) => {
        this.api_ok = false;
        this.on_check_api = false;
        // this.message = [{severity: 'error', summary: 'Api Down', detail: 'Connection Error'}];
      },
  );

    this._auth.onLogin.subscribe((event) => {
      this.getUser();
    });

    this._auth.onLogout.subscribe((event) => {
      this.user = null;
    });

  }

  getUser() {
    this._user.getUser().subscribe(
      (user) => {
        this.user = user;
      }
    )
  }


  ngAfterViewInit() {
    this._router.events
      .subscribe((event) => {
        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          const title = 'deepblock Admin - ' + this.getTitle(this._router.routerState, this._router.routerState.root).join('-');
          this._titleService.setTitle(title);
        }
      });
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  editPassword() {
    this.dialogRef = this.dialog.open(UserDialogPasswordComponent, {
    });
  }

  changeLang() {
    this.translate.use(this.currentLang);
  }
}
