import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Subject} from "rxjs";
import {Cacheable} from "ngx-cacheable";

const cacheBuster$ = new Subject<void>();

const CACHE_PARAM = {
  maxCacheCount: 10,
  cacheBusterObserver: cacheBuster$
};

@Injectable({
  providedIn: 'root'
})
export class SignatoryService {

  constructor(
    private _api: ApiService,
  ) { }

  // @Cacheable(CACHE_PARAM)
  getSignatoryDocuments(id) {
    return this._api.get('signatory/' + id + '/documents?groups[]=signatory:outputWithVitalKeys');
  }

  @Cacheable(CACHE_PARAM)
  getCode(id) {
    return this._api.get('signatory/' + id + '/code');
  }

  @Cacheable(CACHE_PARAM)
  getSmsID(id) {
    return this._api.get('signatory/' + id + '/smsid');
  }
}
