import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { Statistic } from "../Model/Statistic";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class StatisticsService {
  constructor(private _api: ApiService) {}

  getAll(month, year): Observable<Statistic[]> {
    if (environment.api_host.includes("dev")) {
      return this._api.get("statistics", { month, year, preprod: true });
    } else {
      return this._api.get("statistics", { month, year });
    }
  }

  getAllYear(month, year): Observable<Statistic[]> {
    if (environment.api_host.includes("dev")) {
      return this._api.get("statistics", { month, year, csv: true, preprod: true });
    } else {
      return this._api.get("statistics", { month, year, csv: true });
    }
  }

}
