import { Injectable } from "@angular/core";
import { Cacheable } from "ngx-cacheable";
import { Observable, Subject } from "rxjs";
import { ApiService } from "./api.service";
import { Contract } from "../Model/Contract";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";

const cacheBuster$ = new Subject<void>();

const CACHE_PARAM = {
  maxCacheCount: 10,
  cacheBusterObserver: cacheBuster$,
};

@Injectable({
  providedIn: "root",
})
export class ContractService {
  constructor(
    private _api: ApiService,
    private _http: HttpClient,
    private _auth: AuthService
  ) {}

  // @Cacheable(CACHE_PARAM)
  getContracts(
    filters = {},
    search = "",
    sort = null,
    sortDirection = null,
    page = 1,
    pageSize = 20
  ): Observable<Contract[]> {
    filters["page"] = page + 1;
    filters["itemsPerPage"] = pageSize;
    if (sort) {
      filters["order[" + sort + "]"] = sortDirection;
    }
    filters["search"] = search;

    return this._api.get("contracts", filters);
  }

  getContractsSum() {
    return this._api.get("contracts/sum");
  }

  createContract(data, key) {
    return this._api.createContractBigFiles(
      data.subject,
      data.recipients,
      data.copyRecipients,
      data.attachments,
      data.signType,
      key,
      data.isPdfTag,
      data.requireAttachments,
      data.eidasTimestamp,
      data.isCertified,
      data.isHandwrittenSignature
    );
  }

  createChainContract(data, key) {
    return this._api.createChainContract(data, key);
  }

  @Cacheable(CACHE_PARAM)
  getFiles(id) {
    return this._api.get("contracts/" + id + "/file");
  }

  @Cacheable(CACHE_PARAM)
  getSignatoryFiles(id) {
    return this._api.get("signaotry/" + id + "/file");
  }

  sendReminder(contract, signatory = null) {
    if (signatory) {
      return this._api.get(
        "contracts/" + contract.id + "/reminder/" + signatory
      );
    }
    return this._api.get("contracts/" + contract.id + "/reminder");
  }

  resendContract(contract) {
    return this._api.postRow("contracts/" + contract.id + "/resend", {});
  }

  cancelContract(contract) {
    cacheBuster$.next();
    return this._api.postRow("contracts/" + contract.id + "/cancel", {});
  }

  contractComplete(contract) {
    return this._api.postRow("contracts/" + contract.id + "/complete", {});
  }

  getCustomCMTemplate(search) {
    return this._api.requestCustomCM(
      "GET",
      "document-templates?title_contains=" + search
    );
  }

  sendCustomCMRequest(data) {
    data.vitalsignRefreshToken = this._auth.getRefreshToken();
    return this._api.requestCustomCM(
      "POST",
      "signature-requests/from-vitalsign",
      data
    );
  }

  getStatusEmail(data: { contractId: string; signatoryId: string }) {
    // data.vitalsignRefreshToken = this._auth.getRefreshToken();
    /**
     * data type json
     * contractId string required
     * signatoryId string required
     */
    return this._api.requestSendInBlue(data);
  }

  sendDraftContracts(data) {
    data.vitalsignRefreshToken = this._auth.getRefreshToken();
    return this._api.postRow("contracts_draft/create", data);
  }

  getDraftContracts() {
    return this._api.get("contracts_draft");
  }

  resendContractDraft(id) {
    let data = { vitalsignRefreshToken: this._auth.getRefreshToken() };
    return this._api.postRow(`contracts_draft/${id}/resend`, data);
  }
}
